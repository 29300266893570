var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.seckillGoodsList && _vm.seckillGoodsList.length !== 0)?_c('div',{staticClass:"seckill-zone"},[_vm._m(0),(_vm.mobile === true)?_c('div',{staticClass:"goods-content-box"},_vm._l((_vm.seckillGoodsList.slice(0, _vm.loadedDataCount)),function(item){return _c('div',{key:item.id,staticClass:"seckill-item goods-item-box",on:{"click":function($event){return _vm.goGoodDetail(item.id)}}},[_c('div',{staticClass:"goods-img"},[_c('img',{attrs:{"src":item.firstPic || _vm.defaultImg,"alt":""}})]),_c('div',{staticClass:"seckill-statistic-time"},[_c('el-statistic',{attrs:{"value":_vm._f("handleTime")(item.endTime),"value-style":{
            color: '#ffffff',
            fontSize: '17px',
            lineHeight: '35px',
          },"format":"DD天HH时mm分钟ss秒","time-indices":"","title-style":"color:#fff"}})],1),_c('div',{staticClass:"goods-name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"goods-intr"},[_c('span',[_vm._v(_vm._s(item.expansionSize)+" "+_vm._s(item.year))])]),_c('div',{staticClass:"discount-box"}),_c('div',{staticClass:"seckillModule"},[_c('div',{staticClass:"goods-discount-box"},[_c('div',{staticClass:"seckillTit"},[_vm._v("劲爆价")]),_c('div',{staticStyle:{"font-weight":"900","color":"#fba147","font-size":"19px"}},[_vm._v(" ￥"+_vm._s(item.sellPrice)+" ")])])])])}),0):_c('div',{staticClass:"goods-content-box"},_vm._l((_vm.seckillGoodsList),function(item){return _c('div',{key:item.id,staticClass:"seckill-item goods-item-box",on:{"click":function($event){return _vm.goGoodDetail(item.id)}}},[_c('div',{staticClass:"goods-img"},[_c('img',{attrs:{"src":item.firstPic || _vm.defaultImg,"alt":""}})]),_c('div',{staticClass:"seckill-statistic-time"},[_c('el-statistic',{attrs:{"value":_vm._f("handleTime")(item.endTime),"value-style":{
            color: '#ffffff',
            fontSize: '17px',
            lineHeight: '35px',
          },"format":"DD天HH时mm分钟ss秒","time-indices":"","title-style":"color:#fff"}})],1),_c('div',{staticClass:"goods-name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"goods-intr"},[_c('span',[_vm._v(_vm._s(item.expansionSize)+" "+_vm._s(item.year))])]),_c('div',{staticClass:"discount-box"}),_c('div',{staticClass:"seckillModule"},[_c('div',{staticClass:"goods-discount-box"},[_c('div',{staticClass:"seckillTit"},[_vm._v("劲爆价")]),_c('div',{staticStyle:{"font-weight":"900","color":"#fba147","font-size":"19px"}},[_vm._v(" ￥"+_vm._s(item.sellPrice)+" ")])])])])}),0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seckill-tit page-item-tit"},[_c('span',[_vm._v("秒杀专区")])])
}]

export { render, staticRenderFns }