<template>
  <div
    class="seckill-zone"
    v-if="seckillGoodsList && seckillGoodsList.length !== 0"
  >
    <div class="seckill-tit page-item-tit">
      <span>秒杀专区</span>
      <!-- <span class="seckill-all" @click="gotoSeckellAll">查看更多>></span> -->
    </div>
    <div class="goods-content-box" v-if="mobile === true">
      <div
        class="seckill-item goods-item-box"
        v-for="item in seckillGoodsList.slice(0, loadedDataCount)"
        :key="item.id"
        @click="goGoodDetail(item.id)"
      >
        <div class="goods-img">
          <img :src="item.firstPic || defaultImg" alt="" />
        </div>
        <div class="seckill-statistic-time">
          <el-statistic
            :value="item.endTime | handleTime"
            :value-style="{
              color: '#ffffff',
              fontSize: '17px',
              lineHeight: '35px',
            }"
            format="DD天HH时mm分钟ss秒"
            time-indices
            title-style="color:#fff"
          >
          </el-statistic>
        </div>
        <div class="goods-name">{{ item.title }}</div>
        <div class="goods-intr">
          <span>{{ item.expansionSize }}&nbsp;{{ item.year }}</span>
        </div>

        <div class="discount-box"></div>
        <div class="seckillModule">
          <div class="goods-discount-box">
            <div class="seckillTit">劲爆价</div>
            <div style="font-weight: 900; color: #fba147; font-size: 19px">
              ￥{{ item.sellPrice }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goods-content-box" v-else>
      <div
        class="seckill-item goods-item-box"
        v-for="item in seckillGoodsList"
        :key="item.id"
        @click="goGoodDetail(item.id)"
      >
        <div class="goods-img">
          <img :src="item.firstPic || defaultImg" alt="" />
        </div>
        <div class="seckill-statistic-time">
          <el-statistic
            :value="item.endTime | handleTime"
            :value-style="{
              color: '#ffffff',
              fontSize: '17px',
              lineHeight: '35px',
            }"
            format="DD天HH时mm分钟ss秒"
            time-indices
            title-style="color:#fff"
          >
          </el-statistic>
        </div>
        <div class="goods-name">{{ item.title }}</div>
        <div class="goods-intr">
          <span>{{ item.expansionSize }}&nbsp;{{ item.year }}</span>
        </div>

        <div class="discount-box"></div>
        <div class="seckillModule">
          <div class="goods-discount-box">
            <div class="seckillTit">劲爆价</div>
            <div style="font-weight: 900; color: #fba147; font-size: 19px">
              ￥{{ item.sellPrice }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下拉触底加载提示 -->
    <!-- <div v-if="isLoading" class="loading-message" style="margin:0 auto">加载中...</div> -->
    <!-- <div v-if="isError" class="error-message">加载失败，请稍后重试！</div> -->
  </div>
</template>
<script>
import { getHomeSeckillGoodsList } from '@/api/home'
import { validateSeckillGoodStatus } from '@/utils'
import { getwxShare } from '@/utils/wexinShare'
export default {
  data() {
    return {
      seckillGoodsList: [],
      isLoading: false, // 是否正在加载数据
      isError: false, // 是否加载数据失败
      loadedDataCount: 0, // 已加载的数据数量
      mobile: false,
    }
  },
  mounted() {
    if (this.$device.mobile === true) {
      this.mobile = true
    } else {
      this.mobile = false
    }
    this.getSeckillGoodsList()
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动事件
  },
  filters: {
    handleTime(value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = '0' + (date.getMonth() + 1)
      const day = '0' + date.getDate()
      const hours = '0' + date.getHours()
      const minutes = '0' + date.getMinutes()
      const seconds = '0' + date.getSeconds()
      return new Date(`${year}/${month}/${day} ${hours}:${minutes}:${seconds}`)
    },
  },
  methods: {
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      // const parsedURL = new URL(url);
      // const domain = parsedURL.origin;
      var goodsType = 3
      var params={
        url:url,
        goodsId:3,
        goodsType:goodsType
      }
    const path ='#/seckillAll';
     getwxShare(params,url,goodsType,path,'秒杀商品列表')
    },
    loadMoreData() {
      // 判断剩下的数据是否足够加载5条
      if (this.seckillGoodsList.length - this.loadedDataCount < 5) {
        // 剩下的数据不足5条，直接展示剩下的数据
        this.loadedDataCount = this.seckillGoodsList.length
      } else {
        // 加载更多数据
        this.loadedDataCount += 5
      }
    },
    // 获取秒杀商品列表
    async getSeckillGoodsList() {
      // const res = await getHomeSeckillGoodsList();
      // if (res.code == 200) {
      //   this.seckillGoodsList = res.data.seckillList || []
      // }
      try {
        this.isLoading = true
        const res = await getHomeSeckillGoodsList()
        if (res.code == 200) {
          this.seckillGoodsList = res.data.seckillList || []
          if (this.mobile === true) {
            this.loadMoreData()
          }
        } else {
          this.isError = true
        }
      } catch (error) {
        // console.error(error);
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    async goGoodDetail(productID) {
      const isGrounding = await validateSeckillGoodStatus(productID)
      if (isGrounding) {
        this.notifyError('当前商品已下架！')
        return
      }
      // console.log(type);
      this.$router.push({
        path: '/goods/seckilldetails',
        query: { productID },
      })
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      if (
        scrollTop + windowHeight >= scrollHeight - 100 &&
        !this.isLoading &&
        !this.isError
      ) {
        // 判断是否滚动到页面底部
        // this.getSeckillGoodsList();
        this.loadMoreData()
      }
    },
  },
  // beforeDestroy () {
  //   window.removeEventListener('scroll', this.handleScroll); // 移除事件监听器，避免内存泄漏
  // },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.seckill-zone {
  width: $type-area-width;
  margin: 0 auto;
  padding: 40px 0;
  min-height: 63vh;
  .seckill-tit {
    display: flex;
    justify-content: space-between;
    .seckill-all {
      // align-items: flex-end;
      // flex-basis: calc(20%);
      display: none;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* 可以根据需要添加其他样式属性 */
  }

  .pagination-container {
    margin-top: 10px;
  }
  .goods-content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 30px;
    .goods-item-box {
      cursor: pointer;
      width: 18.5%;
      padding: 0px 0 1.5625rem;
      margin: 8px;
      /* margin-right: 1.04167rem; */
      margin-bottom: 1.04167rem;
      background-color: #fff;
      border-radius: 0.3125rem;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .seckill-statistic-time {
        height: 40px;
        background-color: #fa8919;
        opacity: 0.8;
        text-align: center;
      }
      .goods-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 18px;
        font-weight: 400;
        color: #333;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 25px 10px 0;
      }
      .goods-img {
        display: block;
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 100%;
        height: 195px;
        margin: 0 auto;
        border-radius: 0.3125rem 0.3125rem 0 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .goods-intr {
        white-space: normal; /* 多行显示 */
        display: -webkit-box;
        margin: 10px 20px;
        font-size: 12px;
        font-weight: 400;
        color: #fba147;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .goods-payPrice {
        display: -webkit-box;
        margin: 10px 20px;
        font-size: 18px;
        font-weight: 600;
        color: #fa8919;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .discount-box {
        // display: flex;
        // justify-content: center;
        // margin-top: 15px;
      }
      .goods-discount-val {
        color: #d50f1c;
        font-weight: bold;
        font-size: 20px;
        margin-left: 5px;
      }
      .seckillModule {
        div {
          // margin-right: 5px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .preferential {
          font-size: 0.625rem;
          /* width: 100%; */
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
        }
        .goods-discount-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;
          .seckillTit {
            border: 1px solid #fa8919;
            background: #fa8919;
            color: white;
            padding: 3px;
            // width: 49px;
            text-align: center;
            border-radius: 11px;
            font-size: 15px;
            font-weight: 700;
            // padding: 2px;
          }
        }
      }
      .goods-discount-originalPrice {
        margin-left: 10px;
        font-size: 8px;
        display: flex; /* 将容器设置为flex布局 */
        align-items: center; /* 垂直居中对齐 */
        color: #b0b0b0;
      }
      .goods-discount-discount {
        font-size: 12px;

        /* 可选样式 */
        // color: #b0b0b0;
      }
      .gobuy-btn {
        display: flex;
        flex-direction: column;
        background-color: #d50f1c;
        padding: 8px 15px;
        border-radius: 6px;
        color: #fff;
        span {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .goods-item-box:hover {
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 750px) {
  .home-banner {
    min-height: 200px !important;
    max-height: 300px !important;
    img {
      min-height: 200px !important;
      max-height: 300px !important;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .goods-img {
    max-height: 130px;
  }
  .goods-box,
  .seckill-zone {
    width: 95%;
    padding: 0;
    margin-top: 15px;
    background-color: initial;
    .seckill-all {
      display: inline-block !important;
    }
    .goods-item-box {
      width: 48% !important;
      padding: 0 !important;
      padding-bottom: 30px !important;
      margin: 0 !important;
      background-color: #fff !important;
      margin-bottom: 15px !important;
    }
    .goods-content-box {
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .seckill-zone {
    .goods-item-box {
      width: 100% !important;
      .goods-intr {
        font-size: 15px !important;
      }
    }
    .goods-img {
      max-height: 200px;
    }
  }
  .pagination {
    // display: flex;
    // justify-content: space-evenly;
    display: none !important;
  }

  .page-info {
    margin: 0 20px;
  }
}
</style>
