import { render, staticRenderFns } from "./seckillAll.vue?vue&type=template&id=35133777&scoped=true"
import script from "./seckillAll.vue?vue&type=script&lang=js"
export * from "./seckillAll.vue?vue&type=script&lang=js"
import style0 from "./seckillAll.vue?vue&type=style&index=0&id=35133777&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35133777",
  null
  
)

export default component.exports